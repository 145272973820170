// js/chat/api.js

import { CONFIG } from './config.js';
import { constructSystemMessage } from './systemMessageUtil.js';

class APIError extends Error {
  constructor(message, statusCode, details) {
    super(message);
    this.name = 'APIError';
    this.statusCode = statusCode;
    this.details = details;
  }
}

const sendInitialMessage = async (userName, currentPersona) => {
    const userIntroMessage = `The user's name is ${userName}. Please remember this and use their name when appropriate in your responses.`;
    const initialMessage = `Hello, my name is ${userName}. Please introduce yourself and greet me.`;
    const systemMessage = constructSystemMessage(currentPersona);

    const messages = [
        { role: 'system', content: systemMessage },
        { role: 'system', content: userIntroMessage },
        { role: 'user', content: initialMessage }
    ];

    return await sendMessageToAPI(messages, CONFIG.DEFAULT_TEMPERATURE, CONFIG.DEFAULT_MAX_TOKENS);
};

const sendMessageToAPI = async (messages, temperature, maxTokens) => {
    try {
        const response = await fetch(CONFIG.API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                model: CONFIG.DEFAULT_MODEL,
                messages,
                temperature,
                max_tokens: maxTokens
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new APIError(
                errorData.message || 'Unknown API error',
                response.status,
                errorData
            );
        }

        const responseData = await response.json();
        const modelUsed = responseData.model || CONFIG.DEFAULT_MODEL;

        return { responseData, modelUsed };
    } catch (error) {
        if (error instanceof APIError) {
            throw error;
        } else if (error instanceof TypeError) {
            throw new APIError('Network error', 0, { originalError: error.message });
        } else {
            throw new APIError('Unexpected error', 0, { originalError: error.message });
        }
    }
};

export { APIError, sendInitialMessage, sendMessageToAPI };