export function createErrorDisplay(error, onRetry) {
  const errorContainer = document.createElement('div');
  errorContainer.classList.add('bg-rust', 'text-white', 'p-4', 'rounded-lg', 'mb-4', 'flex', 'justify-between', 'items-center');

  const errorMessage = document.createElement('p');
  errorMessage.textContent = `Error: ${error.message}`;
  errorContainer.appendChild(errorMessage);

  if (onRetry) {
    const retryButton = document.createElement('button');
    retryButton.textContent = 'Retry';
    retryButton.classList.add('btn-fallout', 'ml-4');
    retryButton.addEventListener('click', onRetry);
    errorContainer.appendChild(retryButton);
  }

  return errorContainer;
}
