import hljs from 'highlight.js';
import { logDebug } from '../utils.js';

const highlightCodeBlocks = () => {
    const codeBlocks = document.querySelectorAll('pre code');
    codeBlocks.forEach((block) => {
        try {
            hljs.highlightBlock(block);
        } catch (error) {
            logDebug(`Error highlighting code block: ${error.message}`);
        }
    });
};

const addCopyButtonToCodeBlocks = () => {
    const codeBlocks = document.querySelectorAll('.chat-bubble.ai pre');
    codeBlocks.forEach((block) => {
        const copyButton = document.createElement('button');
        copyButton.textContent = 'Copy';
        copyButton.className = 'copy-code-btn';
        copyButton.addEventListener('click', () => copyCodeToClipboard(block, copyButton));
        block.appendChild(copyButton);
    });
};

const copyCodeToClipboard = (block, button) => {
    const code = block.querySelector('code');
    if (code) {
        navigator.clipboard.writeText(code.textContent)
            .then(() => {
                button.textContent = 'Copied!';
                setTimeout(() => {
                    button.textContent = 'Copy';
                }, 2000);
            })
            .catch(err => {
                logDebug(`Failed to copy code: ${err.message}`);
                button.textContent = 'Failed to copy';
            });
    }
};

const initializeCodeHighlighting = () => {
    highlightCodeBlocks();
    addCopyButtonToCodeBlocks();
};

export { initializeCodeHighlighting, highlightCodeBlocks };
