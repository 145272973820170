const logDebug = (message) => {
    const timestamp = new Date().toLocaleTimeString();
    console.log(`${timestamp} - ${message}`);
    const debugLogs = document.getElementById('logs');
    if (debugLogs) {
        debugLogs.textContent += `${timestamp} - ${message}\n`;
        debugLogs.scrollTop = debugLogs.scrollHeight;
    } else {
        console.error('Debug logs element not found');
    }
};

const debounce = (func, delay) => {
    let debounceTimer;
    return function(...args) {
        const context = this;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};

const askForUserName = () => {
    let name = prompt("Welcome, survivor. What's your name?", "Wanderer");
    logDebug(`User name set to: ${name}`);
    return name || "Wanderer";
};

const sanitizeInput = (input) => {
    const div = document.createElement('div');
    div.textContent = input;
    return div.innerHTML;
};

const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    }).format(date);
};

const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
};

export {
    logDebug,
    debounce,
    askForUserName,
    sanitizeInput,
    formatDate,
    generateUniqueId,
    isValidUrl
};
