// js/chat/ui/debugPanel.js

const addCopyButtonToDebugLogs = (container) => {
    const copyButton = document.createElement('button');
    copyButton.textContent = 'Copy Logs';
    copyButton.classList.add('btn-fallout', 'mb-2', 'mr-2');
    container.insertBefore(copyButton, container.firstChild);

    copyButton.addEventListener('click', () => {
        const logsContent = document.getElementById('logs').textContent;
        navigator.clipboard.writeText(logsContent).then(() => {
            console.log('Debug logs copied to clipboard');
        });
    });
};

const addToggleToDebugLogs = (container) => {
    const toggleButton = document.createElement('button');
    toggleButton.textContent = 'Toggle Logs';
    toggleButton.classList.add('btn-fallout', 'mb-2');
    container.insertBefore(toggleButton, container.firstChild);

    const logsElement = document.getElementById('logs');
    toggleButton.addEventListener('click', () => {
        if (logsElement.style.display === 'none') {
            logsElement.style.display = 'block';
            toggleButton.textContent = 'Hide Logs';
        } else {
            logsElement.style.display = 'none';
            toggleButton.textContent = 'Show Logs';
        }
    });
};

export const setupDebugPanel = () => {
    const debugLogsContainer = document.getElementById('debug-logs');
    if (debugLogsContainer) {
        const buttonsContainer = document.createElement('div');
        buttonsContainer.classList.add('mb-2');
        debugLogsContainer.insertBefore(buttonsContainer, debugLogsContainer.firstChild);

        addCopyButtonToDebugLogs(buttonsContainer);
        addToggleToDebugLogs(buttonsContainer);
    }
};