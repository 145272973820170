// personaManager.js
import { logDebug } from './utils.js';

const PERSONA_URL = '/personas.json';

let personas = {};

const loadPersonas = async () => {
    try {
        const response = await fetch(PERSONA_URL);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        personas = data.personas;
        logDebug('Personas loaded successfully');
    } catch (error) {
        logDebug(`Error loading personas: ${error.message}`);
        throw error; // Re-throw the error for the caller to handle
    }
};

const getPersona = (personaName) => {
    const persona = personas[personaName];
    if (!persona) {
        logDebug(`Persona not found: ${personaName}`);
        return null;
    }
    return persona;
};

const getAllPersonas = () => {
    return Object.entries(personas).map(([key, value]) => ({
        name: key,
        ...value
    }));
};

const getDefaultPersona = () => {
    const defaultPersona = personas['Default'] || Object.values(personas)[0];
    if (!defaultPersona) {
        logDebug('No default persona found');
        return null;
    }
    return defaultPersona;
};

const addPersona = (name, personaData) => {
    if (personas[name]) {
        logDebug(`Persona ${name} already exists. Updating...`);
    }
    personas[name] = personaData;
    logDebug(`Persona ${name} added/updated successfully`);
};

const removePersona = (name) => {
    if (personas[name]) {
        delete personas[name];
        logDebug(`Persona ${name} removed successfully`);
        return true;
    }
    logDebug(`Persona ${name} not found for removal`);
    return false;
};

const setupPersonaSelector = () => {
    const personaSelector = document.getElementById('persona-selector');
    const allPersonas = getAllPersonas();

    // Clear existing options
    personaSelector.innerHTML = '';

    // Add options for each persona
    allPersonas.forEach(persona => {
        const option = document.createElement('option');
        option.value = persona.name;
        option.textContent = persona.name;
        personaSelector.appendChild(option);
    });

    // Set the default persona
    const defaultPersona = getDefaultPersona();
    if (defaultPersona) {
        personaSelector.value = defaultPersona.name;
        updatePersonaInfo(defaultPersona);
    }

    // Add event listener for persona change
    personaSelector.addEventListener('change', async (event) => {
        try {
            const selectedPersona = getPersona(event.target.value);
            if (selectedPersona) {
                currentPersona = selectedPersona;
                updatePersonaInfo(currentPersona);
                clearConversationHistory();
                clearChat();
                await sendInitialMessage(userName, currentPersona);
            }
        } catch (error) {
            handleError(error, "Error changing persona");
        }
    });
};

export {
    loadPersonas,
    getPersona,
    getAllPersonas,
    getDefaultPersona,
    addPersona,
    setupPersonaSelector,
    // ... (other exports)
};