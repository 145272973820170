import { logDebug } from './utils.js';

const MAX_HISTORY_LENGTH = 100; // Adjust this value as needed
let conversationHistory = [];

const updateConversationHistory = (role, content) => {
    const message = {
        role,
        content,
        timestamp: new Date().toISOString()
    };
    conversationHistory.push(message);

    // Trim history if it exceeds the maximum length
    if (conversationHistory.length > MAX_HISTORY_LENGTH) {
        conversationHistory = conversationHistory.slice(-MAX_HISTORY_LENGTH);
    }

    // Save to local storage
    try {
        localStorage.setItem('chatHistory', JSON.stringify(conversationHistory));
    } catch (error) {
        logDebug(`Error saving chat history to local storage: ${error.message}`);
    }
};

const loadConversationHistory = () => {
    try {
        const storedHistory = localStorage.getItem('chatHistory');
        if (storedHistory) {
            conversationHistory = JSON.parse(storedHistory);
            logDebug(`Loaded ${conversationHistory.length} messages from history`);
        } else {
            logDebug('No stored chat history found');
        }
    } catch (error) {
        logDebug(`Error loading chat history from local storage: ${error.message}`);
        conversationHistory = [];
    }
    return conversationHistory;
};

const clearConversationHistory = () => {
    conversationHistory = [];
    try {
        localStorage.removeItem('chatHistory');
        logDebug('Chat history cleared');
    } catch (error) {
        logDebug(`Error clearing chat history from local storage: ${error.message}`);
    }
};

const getAPIReadyHistory = () => {
    return conversationHistory.map(({ role, content }) => ({ role, content }));
};

const getConversationHistory = () => {
    return [...conversationHistory];
};

export {
    updateConversationHistory,
    loadConversationHistory,
    clearConversationHistory,
    getAPIReadyHistory,
    getConversationHistory
};
