// main.js

import { APIError, sendMessageToAPI, sendInitialMessage } from './api.js';
import { addMessageToChat, setTypingIndicator, clearChat, setupUI, updatePersonaInfo, createPersonaSelector } from './ui/ui.js';
import { updateConversationHistory, loadConversationHistory, clearConversationHistory, getAPIReadyHistory } from './history.js';
import { logDebug, askForUserName } from './utils.js';
import { loadPersonas, getPersona, getDefaultPersona } from './personaManager.js';
import { setupDebugPanel } from './ui/debugPanel.js';
import { constructSystemMessage } from './systemMessageUtil.js';
import { initializeCodeHighlighting } from './ui/codeHighlight.js';
import { createErrorDisplay } from './ui/errorDisplay.js';
import { CONFIG } from './config.js';

let userName = '';
let currentPersona = null;
let currentModel = CONFIG.DEFAULT_MODEL;

const setupPersonaSelector = () => {
    const existingSelector = document.getElementById('persona-selector');
    if (existingSelector) {
        existingSelector.remove();
    }

    const personaSelector = createPersonaSelector();
    const chatControls = document.getElementById('chat-controls');
    chatControls.insertBefore(personaSelector, chatControls.firstChild);

    personaSelector.value = currentPersona.name;

    personaSelector.addEventListener('change', async (event) => {
        try {
            const selectedPersona = getPersona(event.target.value);
            if (selectedPersona) {
                currentPersona = selectedPersona;
                updatePersonaInfo(currentPersona);
                clearConversationHistory();
                clearChat();
                await sendInitialMessage(userName, currentPersona);
            }
        } catch (error) {
            handleError(error, "Error changing persona");
        }
    });
};

const updateModelInfo = (model) => {
    const modelInfoElement = document.getElementById('model-info');
    if (modelInfoElement) {
        modelInfoElement.textContent = `Current Model: ${model}`;
    }
    currentModel = model;
};

const generateUserIntroMessage = (userName) => {
    return `The user's name is ${userName}. Please remember this and use their name when appropriate in your responses.`;
};

const setupInputs = () => {
    const temperatureInput = document.getElementById('temperature-input');
    const temperatureValue = document.getElementById('temperature-value');
    const maxTokensInput = document.getElementById('max-tokens-input');

    temperatureInput.value = CONFIG.DEFAULT_TEMPERATURE;
    temperatureValue.textContent = CONFIG.DEFAULT_TEMPERATURE;
    maxTokensInput.value = CONFIG.DEFAULT_MAX_TOKENS;
    maxTokensInput.max = CONFIG.MAX_TOKENS_LIMIT;

    temperatureInput.addEventListener('input', (event) => {
        const value = parseFloat(event.target.value);
        temperatureValue.textContent = value.toFixed(1);
    });

    maxTokensInput.addEventListener('input', (event) => {
        const value = parseInt(event.target.value);
        event.target.value = Math.min(Math.max(value, 1), CONFIG.MAX_TOKENS_LIMIT);
    });
};

const sendMessage = async (message = null, isRetry = false) => {
    const userInput = document.getElementById('user-input');
    const temperatureInput = document.getElementById('temperature-input');
    const maxTokensInput = document.getElementById('max-tokens-input');

    let userMessage = message || userInput.value.trim();
    if (!userMessage) {
        logDebug("No user message to send.");
        return;
    }

    if (!isRetry) {
        addMessageToChat('user', userMessage, userName);
        updateConversationHistory('user', userMessage);
        userInput.value = '';
    }

    setTypingIndicator(true);

    try {
        const systemMessage = constructSystemMessage(currentPersona);
        const userIntroMessage = generateUserIntroMessage(userName);
        let messages = [
            { role: 'system', content: systemMessage },
            { role: 'system', content: userIntroMessage },
            ...getAPIReadyHistory(),
            { role: 'user', content: userMessage }
        ];

        logDebug(`Sending messages to API: ${JSON.stringify(messages, null, 2)}`);

        const { responseData, modelUsed } = await sendMessageToAPI(
            messages,
            parseFloat(temperatureInput.value),
            parseInt(maxTokensInput.value, 10)
        );

        updateModelInfo(modelUsed);

        const assistantMessage = responseData.choices[0].message.content;
        logDebug(`Received response from API: ${JSON.stringify(responseData, null, 2)}`);

        addMessageToChat('assistant', assistantMessage, currentPersona.name);
        updateConversationHistory('assistant', assistantMessage);
        initializeCodeHighlighting();
    } catch (error) {
        handleError(error, "Error sending message", () => sendMessage(userMessage, true));
    } finally {
        setTypingIndicator(false);
    }
};

const resetChat = () => {
    clearChat();
    clearConversationHistory();
    userName = askForUserName();
    localStorage.setItem('userName', userName);
    sendInitialMessage(userName, currentPersona);
};

const handleError = (error, context, retryFunction = null) => {
    console.error(`${context}:`, error);
    logDebug(`${context}: ${error.message}`);

    let errorMessage = `An error occurred: ${error.message}`;
    if (error instanceof APIError) {
        errorMessage += ` (Status: ${error.statusCode})`;
    }

    const errorDisplay = createErrorDisplay(errorMessage, retryFunction);
    document.getElementById('chat-container').appendChild(errorDisplay);
};

const initializeChat = async () => {
    try {
        logDebug('Initializing chat...');
        await loadPersonas();
        logDebug('Personas loaded');
        userName = localStorage.getItem('userName') || askForUserName();
        localStorage.setItem('userName', userName);

        currentPersona = getDefaultPersona();
        updatePersonaInfo(currentPersona);
        updateModelInfo(currentModel);

        setupPersonaSelector();
        setupInputs();

        const storedHistory = await loadConversationHistory();
        logDebug(`Loaded stored history: ${JSON.stringify(storedHistory)}`);

        if (storedHistory.length > 0) {
            storedHistory.forEach(message => {
                addMessageToChat(message.role, message.content, message.role === 'user' ? userName : currentPersona.name, new Date(message.timestamp));
            });
        } else {
            const userIntroMessage = generateUserIntroMessage(userName);
            updateConversationHistory('system', userIntroMessage);
            await sendInitialMessage(userName, currentPersona);
        }

        setupUI(sendMessage, resetChat);
        setupDebugPanel();
        initializeCodeHighlighting();
        setupAccessibilityFeatures();
    } catch (error) {
        logDebug(`Error initializing chat: ${error.message}`);
        handleError(error, "An error occurred during initialization");
    }
};

const setupAccessibilityFeatures = () => {
    const chatContainer = document.getElementById('chat-container');
    const sendButton = document.getElementById('send-button');

    // Add ARIA live region for screen readers
    chatContainer.setAttribute('aria-live', 'polite');
    chatContainer.setAttribute('aria-atomic', 'false');

    // Improve keyboard navigation
    sendButton.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            sendButton.click();
        }
    });

    // Add skip to main content link
    const skipLink = document.createElement('a');
    skipLink.href = '#chat-container';
    skipLink.textContent = 'Skip to main content';
    skipLink.className = 'visually-hidden focus:not-sr-only focus:absolute focus:z-10 focus:p-2 focus:bg-wasteland focus:text-radioactive';
    document.body.insertBefore(skipLink, document.body.firstChild);
};

const addAnimationEffects = () => {
    const chatBubbles = document.querySelectorAll('.chat-bubble');
    chatBubbles.forEach((bubble, index) => {
        bubble.style.animationDelay = `${index * 0.1}s`;
        bubble.classList.add('animate-fadeIn');
    });
};

const setupAutoResizeTextarea = () => {
    const textarea = document.getElementById('user-input');
    textarea.addEventListener('input', function () {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 'px';
    });
};

const setupEmojiPicker = () => {
    const emojiButton = document.createElement('button');
    emojiButton.textContent = '😀';
    emojiButton.className = 'emoji-button';
    emojiButton.setAttribute('aria-label', 'Open emoji picker');

    const emojiPicker = document.createElement('div');
    emojiPicker.className = 'emoji-picker';
    const emojis = ['😀', '😂', '🤔', '👍', '❤️', '🚀', '🌈', '🔥', '🎉', '🍕',
        '😎', '🥳', '🤩', '😊', '🙌', '💯', '🌟', '🍀', '🦄', '🎈',
        '🌺', '🍦', '🎸', '🏆', '📚', '🧠', '💡', '🌍', '🎭', '🚴',
        '💭', '❓', '🤖', '🤯', '🤨', '🧐', '🔍', '💻', '⌨️', '🖱️',
        '📱', '🌐', '✅', '❌', '⭐', '⏳', '🔁', '📝', '🗂️', '🔒'];

    const fragment = document.createDocumentFragment();
    emojis.forEach(emoji => {
        const emojiOption = document.createElement('button');
        emojiOption.textContent = emoji;
        emojiOption.addEventListener('click', () => {
            const userInput = document.getElementById('user-input');
            userInput.value += emoji;
            userInput.focus();
            emojiPicker.classList.remove('active');
        });
        fragment.appendChild(emojiOption);
    });
    emojiPicker.appendChild(fragment);

    const chatControls = document.getElementById('chat-controls');
    chatControls.appendChild(emojiButton);
    chatControls.appendChild(emojiPicker);

    emojiButton.addEventListener('click', (e) => {
        e.stopPropagation();
        emojiPicker.classList.toggle('active');
    });

    document.addEventListener('click', (e) => {
        if (!emojiPicker.contains(e.target) && e.target !== emojiButton) {
            emojiPicker.classList.remove('active');
        }
    });
};



export { initializeChat };

document.addEventListener('DOMContentLoaded', () => {
    initializeChat();
    setupAutoResizeTextarea();
    setupEmojiPicker();
});