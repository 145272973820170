// ui.js

import { logDebug, debounce } from '../utils.js';
import { getAllPersonas } from '../personaManager.js';
import { highlightCodeBlocks, initializeCodeHighlighting } from './codeHighlight.js';

const addMessageToChat = (role, content, userName, timestamp = new Date(), onRetry = null) => {
    const chatContainer = document.getElementById('chat-container');
    const messageElement = document.createElement('div');
    messageElement.classList.add('chat-bubble', 'mb-4', 'p-4', 'rounded', 'opacity-0');
    messageElement.classList.add(role === 'user' ? 'human' : 'ai');

    const roleClasses = {
        assistant: ['bg-wasteland', 'text-radioactive'],
        user: ['bg-rust', 'text-gray-300'],
        system: ['bg-red-800', 'text-white', 'font-bold']
    };

    messageElement.classList.add(...(roleClasses[role] || []));

    const timeString = timestamp.toLocaleTimeString();
    messageElement.innerHTML = `<strong>${role === 'user' ? userName : role} (${timeString}):</strong> ${marked.parse(content)}`;

    if (role === 'system' && onRetry) {
        const retryButton = document.createElement('button');
        retryButton.textContent = 'Retry';
        retryButton.classList.add('btn-fallout', 'mt-2');
        retryButton.addEventListener('click', onRetry);
        messageElement.appendChild(retryButton);
    }

    chatContainer.appendChild(messageElement);
    chatContainer.scrollTop = chatContainer.scrollHeight;
    initializeCodeHighlighting();
    highlightCodeBlocks();

    // Trigger animation
    setTimeout(() => {
        messageElement.classList.add('animate-fadeIn');
    }, 10);
};

const setTypingIndicator = (isTyping) => {
    const typingIndicator = document.getElementById('typing-indicator');
    typingIndicator.style.display = isTyping ? 'block' : 'none';
};

const clearChat = () => {
    const chatContainer = document.getElementById('chat-container');
    chatContainer.innerHTML = '';
};

const setupUI = (sendMessageCallback, resetChatCallback) => {
    const userInput = document.getElementById('user-input');
    const sendButton = document.getElementById('send-button');
    const resetButton = document.getElementById('reset-button');
    const exportButton = document.getElementById('export-chat');

    if (!userInput || !sendButton || !resetButton || !exportButton) {
        console.error('One or more UI elements not found');
        return;
    }

    const handleSendMessage = () => {
        const message = userInput.value.trim();
        if (message) {
            sendMessageCallback(message);
            userInput.value = '';
            userInput.style.height = 'auto'; // Reset height after sending
        }
    };

    sendButton.addEventListener('click', handleSendMessage);
    userInput.addEventListener('keypress', (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    });

    resetButton.addEventListener('click', resetChatCallback);
    exportButton.addEventListener('click', exportChat);
};

const updatePersonaInfo = (persona) => {
    const personaInfoElement = document.getElementById('persona-info');
    if (personaInfoElement && persona) {
        personaInfoElement.innerHTML = `
            <h3 class="text-xl font-bold text-steel">${persona.name}</h3>
            <p class="text-gray-300">${persona.description}</p>
        `;
        personaInfoElement.classList.add('animate-fadeIn');
    }
};

const createPersonaSelector = () => {
    const personas = getAllPersonas();
    const select = document.createElement('select');
    select.id = 'persona-selector';
    select.classList.add('w-full', 'p-2', 'mb-4', 'bg-gray-800', 'text-gray-300', 'border', 'border-rust', 'rounded', 'focus:ring-2', 'focus:ring-radioactive', 'focus:border-transparent', 'transition-all', 'duration-300', 'ease-in-out');

    personas.forEach(persona => {
        const option = document.createElement('option');
        option.value = persona.name;
        option.textContent = persona.name;
        select.appendChild(option);
    });

    return select;
};

const exportChat = () => {
    const chatContainer = document.getElementById('chat-container');
    const chatContent = chatContainer.innerText;
    const blob = new Blob([chatContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'chat_export.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};

export {
    addMessageToChat,
    setTypingIndicator,
    clearChat,
    setupUI,
    updatePersonaInfo,
    createPersonaSelector
};