import { logDebug } from './utils.js';

const constructSystemMessage = (currentPersona) => {
    const currentDate = new Date().toLocaleString();
    let systemMessage = `Current date and time: ${currentDate}. `;

    if (currentPersona) {
        systemMessage += currentPersona.system_message + ' ' + currentPersona.persona;
        
        if (currentPersona.preferences) {
            const preferencesString = JSON.stringify(currentPersona.preferences);
            systemMessage += ` Preferences: ${preferencesString}`;
        }

        logDebug(`Constructed system message for persona: ${currentPersona.name}`);
    } else {
        systemMessage += "You are a helpful AI assistant.";
        logDebug('Constructed default system message');
    }

    return systemMessage;
};

const getPersonaTopicExamples = (currentPersona) => {
    if (currentPersona && currentPersona.topic_examples) {
        return currentPersona.topic_examples;
    }
    return [];
};

const appendCustomInstructions = (systemMessage, customInstructions) => {
    if (customInstructions && customInstructions.trim()) {
        systemMessage += ` Additional instructions: ${customInstructions.trim()}`;
        logDebug('Appended custom instructions to system message');
    }
    return systemMessage;
};

export {
    constructSystemMessage,
    getPersonaTopicExamples,
    appendCustomInstructions
};
